<template>
    <div>
        <c-section :title="$t('battery-model-list.view.title')" :subtitle="$t('battery-model-list.view.subtitle')"
            class="secondary">
            <v-card class="mt-5 mb-5" tile>
                <c-item-list :title="$t('battery-model-list.view.toolbar.title')" :items="items" :pagination="pagination"
                    @refresh-list="refresh" @previous-page="onPreviousPage" @next-page="onNextPage"
                    @item-added="onItemAdded" @item-edited="onItemEdited" @item-deleted="onItemDeleted"
                    @item-list-filter-applied="onFilterChanged" :disable-add-item="!hasCreateRole"
                    :disable-edit-item="!hasEditRole && !hasReadRole" :disable-delete-item="!hasDeleteRole"
                    supports-delete-item>

                </c-item-list>
                <v-card-text></v-card-text>
                <v-divider />
                <v-subheader class="grey lighten-5">{{
                    $t("battery-model-list.view.help.title")
                }}</v-subheader>
                <v-card-text class="grey lighten-5">{{
                    $t("battery-model-list.view.help.description")
                }}</v-card-text>
            </v-card>
        </c-section>
    </div>
</template>
  
<script>
import Roles from "@/roles";
import { get } from "lodash";

import BatteryModelListModule from "../../../store/masterdata/battery-models/battery-model-list.module";

const MODULE_ID = "batteryModelList";

export default {
    name: "battery-model-list",

    inject: [
        "errorHandlerService",
        "progressIndicatorService",
        "userContextService",
    ],


    computed: {
        response() {
            return this.$store.getters[MODULE_ID + "/response"];
        },

        items() {
            return this.response.items;
        },

        pagination() {
            return get(this.response, "pagination", {});
        },

        hasReadRole() {
            return this.userContextService.hasAllRoles(Roles.BATTERYMODEL_READ);
        },

        hasEditRole() {
            return this.userContextService.hasAllRoles(Roles.BATTERYMODEL_EDIT);
        },

        hasCreateRole() {
            return this.userContextService.hasAllRoles(Roles.BATTERYMODEL_CREATE);
        },

        hasDeleteRole() {
            return this.userContextService.hasAllRoles(Roles.BATTERYMODEL_DELETE);
        },
    },

    methods: {
        async refresh() {
            this.loadPage(0, 50);
        },

        async onNextPage({ from, pageSize }) {
            this.loadPage(from, pageSize);
        },

        async onPreviousPage({ from, pageSize }) {
            this.loadPage(from, pageSize);
        },

        async loadPage(from, pageSize) {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/loadResponse", {
                    from,
                    pageSize,
                });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        onItemEdited(event) {
            if (event) {
                this.$router.push({
                    name: "BatteryModelEditorView",
                    params: {
                        name: event.item.name,
                    },
                });
            } else {
                this.$log.debug(
                    "Event object is undefined. Can't navigate to prediction scenario."
                );
            }
        },

        onItemAdded() {
            this.$router.push({ name: "BatteryModelWizardView" });
        },

        async onItemDeleted({ item }) {
            this.progressIndicatorService.show();
            try {
                // Delete the item, then reload the list in order to reflect change.
                await this.$store.dispatch(MODULE_ID + "/deleteItem", item);
                this.refresh();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async onFilterChanged(event) {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/updateFilter", event.filter);
                await this.refresh();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        }
    },

    created() {
        // Dynamically register the vuex store module for managing the data.
        if (!this.$store.hasModule(MODULE_ID)) {
            this.$store.registerModule(MODULE_ID, BatteryModelListModule);
        }

        this.refresh();
    },
    destroyed() {
        // Unregister the vuex store if it has been registered before.
        if (this.$store.hasModule(MODULE_ID)) {
            this.$store.unregisterModule(MODULE_ID);
            this.$log.debug(`Vuex Store ${MODULE_ID} has been unregistered.`);
        }
    }
};
</script>
  
<i18n>
  {
      "en" : {
          "battery-model-list.view.title" : "Battery Model Management",
          "battery-model-list.view.subtitle" : "You may use this dialog for creating, editing or deleting a Battery Model.",
          "battery-model-list.view.toolbar.title" : "Battery Model",  
          "battery-model-list.view.columns.open" : "Open",
          "battery-model-list.view.columns.apis" : "APIs",  
          "battery-model-list.view.help.title" : "What is a Battery Model?",
          "battery-model-list.view.help.description" : "Battery models are used to characterize batteries. Features such as the weight and chemistry of a battery are listed here.",  
          "battery-model-list.view.dialogs.delete.actions.confirm" : "Confirm",
          "battery-model-list.view.dialogs.delete.actions.cancel" : "Cancel"
  
  
      },
  
      "de" : {
          "battery-model-list.view.title" : " Verwaltung der Batterie-Modelle",
          "battery-model-list.view.subtitle" : "Sie können neue Batterie-Modelle anlegen, editieren oder löschen.",
          "battery-model-list.view.toolbar.title" : "Batterie-Modell",  
          "battery-model-list.view.columns.open" : "Öffnen",
          "battery-model-list.view.columns.apis" : "APIs",  
          "battery-model-list.view.help.title" : "Was ist ein Batterie-Modell?",
          "battery-model-list.view.help.description" : "Batterie-Modelle werden genutzt um Batterien zu charakterisieren. Merkmale wie z.B. Gewicht und Chemie einer Batterie werden hier erfasst.",
          "battery-model-list.view.dialogs.delete.actions.confirm" : "Bestätigen",
          "battery-model-list.view.dialogs.delete.actions.cancel" : "Abbrechen"
  
      }
  }
  </i18n>