<template>
    <v-container fluid class="secondary pt-10 pb-10">
        <v-row justify="center">
            <v-col cols="12" lg="9">
                <c-base-dialog :value="batteryModel" :title="$t('battery-model-wizard.view.title')"
                    :subtitle="$t('battery-model-wizard.view.subtitle')" color="secondary" @dialog-saved="save"
                    @dialog-canceled="cancel">
                    <template v-slot:default="props">
                        <v-card flat>
                            <v-card-text>
                                <c-name-input v-model="props.item.name" filled />
                                <c-item-selector v-model="batteryCategory" :items="batteryCategories"
                                    
                                    :rules="[v => v && !!v.trim() || $t('battery-model-wizard.view.category.valuecheck')]"
                                    :label="$t('battery-model-wizard.view.category')" filled></c-item-selector>
                                <c-item-selector v-model="manufacturerId" :items="manufacturer.items"

                                    :rules="[v => v && !!v.trim() || $t('battery-model-wizard.view.manufacturer.valuecheck')]"
                                    :label="$t('battery-model-wizard.view.manufactuerer')" filled></c-item-selector>
                            </v-card-text>
                        </v-card>
                    </template>

                </c-base-dialog>
            </v-col>

        </v-row>
    </v-container>
</template>
  
<script>

import BatteryModelWizardModule from "../../../store/masterdata/battery-models/battery-model-wizard.module";

const MODULE_NAME = "batteryModelWizard";

export default {
    name: "battery-model-wizard",

    inject: [
        "errorHandlerService",
        "progressIndicatorService",
        "envContextService",
    ],


    methods: {
        async save() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/createBatteryModel");
                const modelName = this.batteryModel.name;
                this.$router.push({
                    name: "BatteryModelEditorView",
                    params: { name: modelName },
                });
                this.reset();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.reset();
            this.$router.push({
                name: "BatteryModelListView",
            });
        },

        setTenantId(tenantId) {
            this.$store.dispatch(MODULE_NAME + "/updateTenantId", tenantId);
        },

        async reset() {
            await this.$store.dispatch(MODULE_NAME + "/resetBatteryModel");
            this.setTenantId(this.tenantId);
        },
    },

    computed: {
        batteryModel: {
            get() {
                return this.$store.getters[MODULE_NAME + "/batteryModel"] || {};
            },

            set(item) {
                this.$store
                    .dispatch(MODULE_NAME + "/updateBatteryModel", item)
                    .catch(this.errorHandlerService.handleError);
            },
        },

        tenantId() {
            return this.envContextService.tenantId;
        },
        batteryCategories: {
            get() {
                return this.$store.getters[MODULE_NAME + "/batteryCategories"];
            }
        },
        batteryCategory: {
            get() {
                return this.$store.getters[MODULE_NAME + "/batteryCategory"];
            },
            set(value) {
                this.$store.dispatch(MODULE_NAME + "/updateBatteryCategory", value);
            }
        },
        manufacturer() {
            return this.$store.getters[MODULE_NAME + "/manufacturer"];
        },

        manufacturerId: {
            get() {
                return this.$store.getters[MODULE_NAME + "/manufacturerId"];
            },

            set(item) {
                this.$store
                    .dispatch(MODULE_NAME + "/updateManufacturerId", item)
                    .catch(this.errorHandlerService.handleError);
            },
        },

    },


    async created() {
        if (!this.$store.hasModule(MODULE_NAME)) {
            this.$store.registerModule(MODULE_NAME, BatteryModelWizardModule);
        }
        this.reset();
        await this.$store.dispatch(MODULE_NAME + "/loadCategories");
        await this.$store.dispatch(MODULE_NAME + "/loadManufacturers");
    },

    destroyed() {
        // Unregister the vuex store if it has been registered before.
        if (this.$store.hasModule(MODULE_NAME)) {
            this.$store.unregisterModule(MODULE_NAME);
            this.$log.debug(`Vuex Store ${MODULE_NAME} has been unregistered.`);
        }
    }
};
</script>
  
<i18n>
  {
      "en" : {
          "battery-model-wizard.view.title" : "Create New Battery Model",
          "battery-model-wizard.view.subtitle" : "This wizard will guide you through the required steps for creating a new Battery Model.",
          "battery-model-wizard.view.batteryweight":"Battery Weight",
          "battery-model-wizard.view.chemistry":"Battery Chemistry",
          "battery-model-wizard.view.category":"Battery Category",
          "battery-model-wizard.view.manufactuerer":"Manufacturer",
          "battery-model-wizard.view.category.valuecheck":"Please assign a Battery Category.",
          "battery-model-wizard.view.manufacturer.valuecheck" : "Please assign a Manufacturer."
      },
   
      "de" : {
          "battery-model-wizard.view.title" : "Neues Batterie-Modell anlegen",
          "battery-model-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage eines neuen Batterie-Modells.",
          "battery-model-wizard.view.batteryweight":"Batteriegewicht",
          "battery-model-wizard.view.chemistry":"Batteriechemie",
          "battery-model-wizard.view.category":"Batterie-Kategorie",
          "battery-model-wizard.view.manufactuerer":"Hersteller",
          "battery-model-wizard.view.category.valuecheck":"Bitte wählen Sie eine Batterie-Kategorie.",
          "battery-model-wizard.view.manufacturer.valuecheck" : "Bitte wählen Sie einen Hersteller."
      }
  }
  </i18n>
  
  
  