import { get, isEmpty } from 'lodash';
import api from '@/solutions/battery-data-hub/api/battery-data-hub.api';


export default {

    namespaced: true,

    state: {
        battery: {
            
            batteryPack: {},
            batteryModel: {},
            batteryCategory: {},
            batteryStatus: {},

            manufacturer: {},
            manufacturerPlant: {},

        },

        materialComposition: [],

        materials: []
    },

    mutations: {

        setBattery(state, battery) {
            state.battery = !isEmpty(battery) 
                          ? battery 
                          : { 
                                batteryPack: {} 
                            };
        },

        setMaterialComposition(state, materialComposition) {
            state.materialComposition = !isEmpty(materialComposition) ? materialComposition : [];
        },

        setMaterials(state, materials) {
            state.materials = !isEmpty(materials) ? materials : [];
        }
    },

    actions: {
        
        async loadBatteryData(context, {name}){

            const response = await api.masterdata.batteryPacks.findById(name);
            const battery = get(response, 'item');
            context.commit('setBattery', battery);
        },

        async loadMaterialCompositionData(context, {batteryModelId}) {
            const response = await api.masterdata.batteryMaterialCompositions.findByBatteryModel(batteryModelId, 0, 100);
            const items = get(response, 'items', []);
            context.commit('setMaterialComposition', items);
        },

        
        async loadMaterialData(context, {batteryModelId}) {
            const response = await api.masterdata.batteryMaterials.findByBatteryModel(batteryModelId, 0, 100);
            const items = get(response, 'items', []);
            context.commit('setMaterials', items);
        }
        
    },

    getters: {
        
        battery(state) {
            return get(state, 'battery', { batteryPack: {}});
        },

        batteryMaterialComposition(state) {
            return get(state, 'materialComposition', []);
        },

        materials(state) {
            return get(state, 'materials', []);
        }
    }


}