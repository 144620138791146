<template>
    <dxs-app-form :title="$t('battery-material-matrix.view.title')" :subtitle="$t('battery-material-matrix.view.subtitle')">
        <template v-slot:actions>
            <v-btn icon><v-icon>refresh</v-icon></v-btn>
        </template>
        <v-card tile>
            <v-toolbar flat>
                <v-toolbar-title >{{ $t('battery-material-matrix.view.result.totalItemCount', {totalItemCount: totalItemCount}) }}</v-toolbar-title>
                <v-toolbar-items>
                    <v-btn icon @click="toggleSettingsSummaryDialog">
                        <v-icon v-if="settingsSummaryDialog.display">expand_less</v-icon>
                        <v-icon v-else>expand_more</v-icon>
                    </v-btn>
                </v-toolbar-items>
                <v-spacer />
                <v-toolbar-items>
                    <v-btn icon @click="refresh"><v-icon>refresh</v-icon></v-btn>
                    <v-menu top >
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon v-if="isViewTypeTable">table_view</v-icon>
                                <v-icon v-if="isViewTypeTile">grid_view</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item @click="setViewTypeTable">
                                <v-list-item-icon><v-icon>table_view</v-icon></v-list-item-icon>
                                <v-list-item-title>{{$t('battery-material-matrix.view.actions.view.table')}}</v-list-item-title>
                            </v-list-item>
                            
                            <v-list-item @click="setViewTypeTile">
                                <v-list-item-icon><v-icon>grid_view</v-icon></v-list-item-icon>
                                <v-list-item-title>{{$t('battery-material-matrix.view.actions.view.tiles')}}</v-list-item-title>
                            </v-list-item>
                            <v-divider />
                            <v-list-item @click="viewDialog.displayCategoryColumn = !viewDialog.displayCategoryColumn">
                                <v-list-item-icon><v-icon v-if="viewDialog.displayCategoryColumn">check_box</v-icon><v-icon v-else>check_box_outline_blank</v-icon></v-list-item-icon>
                                <v-list-item-title>{{$t('battery-material-matrix.view.columns.category')}}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="viewDialog.displayManufacturerColumn = !viewDialog.displayManufacturerColumn">
                                <v-list-item-icon><v-icon v-if="viewDialog.displayManufacturerColumn">check_box</v-icon><v-icon v-else>check_box_outline_blank</v-icon></v-list-item-icon>
                                <v-list-item-title>{{$t('battery-material-matrix.view.columns.manufacturer')}}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="viewDialog.displayBatteryPackCountColumn = !viewDialog.displayBatteryPackCountColumn">
                                <v-list-item-icon><v-icon v-if="viewDialog.displayBatteryPackCountColumn">check_box</v-icon><v-icon v-else>check_box_outline_blank</v-icon></v-list-item-icon>
                                <v-list-item-title>{{$t('battery-material-matrix.view.columns.batteriesTotal')}}</v-list-item-title>
                            </v-list-item>
                             <v-divider />
                            <v-list-item @click="viewDialog.displayEmptyRows = !viewDialog.displayEmptyRows">
                                <v-list-item-icon><v-icon v-if="viewDialog.displayEmptyRows">check_box</v-icon><v-icon v-else>check_box_outline_blank</v-icon></v-list-item-icon>
                                <v-list-item-title>{{$t('battery-material-matrix.view.actions.view.showEmptyRows')}}</v-list-item-title>
                            </v-list-item>

                        </v-list>
                    </v-menu>
                    <v-btn icon @click="showFilterDialog"><v-icon>filter_list</v-icon></v-btn>
                    <v-btn icon @click="showViewDialog"><v-icon>visibility</v-icon></v-btn>



                </v-toolbar-items>
            </v-toolbar>
            <!-- Settings Summary -->
             <template v-if="settingsSummaryDialog.display">
            <v-card tile flat class="grey lighten-3" >
                <v-subheader>{{$t('battery-material-matrix.view.dialogs.filterSettings.sections.batteryModels')}}</v-subheader>
                <v-card-text>
                    <v-chip label class="mr-3">{{ $t('battery-material-matrix.view.dialogs.settings.summary.selection', {count: batteryModelFilter.length}) }}</v-chip>
                    <v-chip label v-for="(item, index) of batteryModelFilter" :key="`chip_batteryModel_${index}`" class="mr-3 accent">{{ item }}</v-chip>
                </v-card-text>
                <v-divider />
                <v-subheader>{{$t('battery-material-matrix.view.dialogs.viewSettings.sections.batteryMaterial')}}</v-subheader>
                <v-card-text>
                    <v-chip label class="mr-3">{{ $t('battery-material-matrix.view.dialogs.settings.summary.selection', {count: batteryMaterialFilter.length}) }}</v-chip>
                    <v-chip label v-for="(item, index) of batteryMaterialFilter" :key="`chip_batteryMaterial_${index}`" class="mr-3 accent">{{ item }}</v-chip>
                </v-card-text>
            </v-card>
            <v-divider />
        </template>
            <!-- DATA IS NOT AVAILABLE -->
            <v-container v-if="modelRowsEmpty" fluid class="grey lighten-3">
                <v-row>
                    <v-col>
                        <v-card tile>
                            <v-card-text class="text-center"><v-icon x-large color="error">sentiment_dissatisfied</v-icon></v-card-text>
                            <v-card-text class="text-center">
                                    <div class="text-h6">{{ $t('battery-material-matrix.view.noData.title') }}</div>
                                    <div >{{ $t('battery-material-matrix.view.noData.subtitle') }}</div>
                                </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>

            <!-- DATA IS AVAILABLE -->
            <template v-else>
                <v-container v-if="!viewDialog.displayEmptyRows" fluid class="pa-0">
                    <v-row>
                        <v-col>
                            <v-card tile flat>
                                <v-card-text>
                                    <v-icon color="grey" class="mr-3">info</v-icon>{{ $t('battery-material-matrix.view.dialogs.rows.emptyRowsHidden') }}
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container fluid class="pa-0" v-if="isViewTypeTable">
                    <v-row  no-gutters>
                       <v-col cols="2">
                       </v-col>
                       <v-col cols="10">
                            <table class="matrix">
                                <tbody>
                                    <tr>
                                        <td v-for="(item, index) of modelColumns" :key="`th_material_${index}`" class="matrix-cell-right text-center text-caption" :style="item.data && item.data.color ? {backgroundColor: item.data.color} : {backgroundColor: 'red'}" :title="$dxs.i18n.localizedName(item, $i18n.locale) + ` (${item.name})`">
                                            <span>{{ $dxs.i18n.localizedName(item, $i18n.locale) }}</span>
                                        </td>

                                        <td class="matrix-cell-right text-center text-caption grey lighten-2">...</td>
                                    </tr>
                                </tbody>
                            </table>
                        </v-col>
                    </v-row>
                    <v-row  no-gutters>
                        <v-col cols="2">
                            <div ref="headerLeft" class="header-container-left">
                                <table class="matrix"> 
                                    <tbody>
                                        <tr class="grey">
                                            <td class="matrix-cell-left text-left text-caption" :title="$t('battery-material-matrix.view.columns.batteryModel')">{{ $t('battery-material-matrix.view.columns.batteryModel')}}</td>
                                            <td v-if="viewDialog.displayCategoryColumn" class="matrix-cell-left text-left text-caption" :title="$t('battery-material-matrix.view.columns.category')">{{ $t('battery-material-matrix.view.columns.category')}}</td>
                                            <td v-if="viewDialog.displayManufacturerColumn" class="matrix-cell-left text-left text-caption" :title="$t('battery-material-matrix.view.columns.manufacturer')">{{ $t('battery-material-matrix.view.columns.manufacturer')}}</td>
                                            <td v-if="viewDialog.displayBatteryPackCountColumn" class="matrix-cell-left text-center text-caption" :title="$t('battery-material-matrix.view.columns.batteriesTotal')">{{ $t('battery-material-matrix.view.columns.batteriesTotal')}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div ref="tableLeft"  class="table-container-left" @scroll="onScrollLeft">
                                <table class="matrix">
                                    <tbody>
                                        <template v-for="(item, index) of modelRowsWithOther">
                                            <tr :key="`item-tr-left-${index}`" v-if="canDisplayRow(item)">
                                                <td class="matrix-cell-left text-left text-caption" :title="$dxs.i18n.localizedName(item.batteryModel, $i18n.locale)">{{ $dxs.i18n.localizedName(item.batteryModel, $i18n.locale) }}</td>
                                                <td v-if="viewDialog.displayCategoryColumn" class="matrix-cell-left text-left text-caption" :title="$dxs.i18n.localizedName(item.batteryCategory, $i18n.locale)">{{ $dxs.i18n.localizedName(item.batteryCategory, $i18n.locale)}}</td>
                                                <td v-if="viewDialog.displayManufacturerColumn" class="matrix-cell-left text-left text-caption" :title="$dxs.i18n.localizedName(item.manufacturer, $i18n.locale)">{{  $dxs.i18n.localizedName(item.manufacturer, $i18n.locale) }}</td>
                                                <td v-if="viewDialog.displayBatteryPackCountColumn" class="matrix-cell-left text-center text-caption" :title="$dxs.i18n.localizedName(item.manufacturer, $i18n.locale)">{{item.batteryPackCount}}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                            
                        </v-col>
                        
                        <v-col cols="10">
                            <table class="matrix">
                                <tbody>
                                    <tr>
                                        <td v-for="(item, index) of modelColumns" :key="`th_cas_${index}`" class="matrix-cell-right text-center text-caption grey lighten-1" :title="$dxs.i18n.localizedName(item, $i18n.locale) + ` (${item.name})`">
                                            <span v-if="item.data.casIdentifier" class="text-caption">
                                                <a :href="'https://commonchemistry.cas.org/detail?ref=' +  item.data.casIdentifier" class="cas-link" target="_blank">{{ item.data.casIdentifier }}</a>
                                            </span>
                                            <span v-else class="text-caption">-</span>
                                           
                                        </td>

                                        <td class="matrix-cell-right text-center text-caption grey lighten-2">...</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div ref="tableRight" class="table-container-right" @scroll="onScrollRight">
                                <table class="matrix">
                                    <tbody>
                                        <template v-for="(row, rowIndex) of modelRowsWithOther">
                                            <tr v-if="canDisplayRow(row)" :key="`item-tr-right-${rowIndex}`">
                                                <td v-for="(col, colIndex) of modelColumns" :key="`td_material_${colIndex}`" class="matrix-cell-right text-center text-caption" :title="row.material[col.name] ? row.material[col.name]  : '-'" :class="{'grey lighten-3': row.material[col.name] === 0}">
                                                    <span v-if="row.material[col.name] === 0">-</span>
                                                    <span v-else>{{ row.material[col.name] }} </span>
                                                </td>
                                                <td class="matrix-cell-right text-center text-caption" :class="{'grey lighten-3': row.other === 0}">
                                                    <span v-if="row.other === 0">-</span>
                                                    <span v-else>{{ row.other }} </span>
                                                </td>
                                            </tr>
                                        </template>
                                    
                                    </tbody>
                                </table>
                            </div>
                        </v-col>
                    
                    </v-row>
                </v-container>

                <v-container v-else fluid class="grey lighten-3" >
                    <v-row>
                        <template v-for="(item, index) of chartValues">
                            <v-col cols="12" md="4" lg="3" v-if="canDisplayRow(item)"  :key="`row-card-${index}`">

                                <dxs-data-distribution-card 
                                    :title="$dxs.i18n.localizedName(item, $i18n.locale)" 
                                    :subtitle="$t('battery-material-matrix.view.tiles.subtitle', {count: item.batteryPackCount})"
                                    :items="item.items"
                                    chartType="donut"
                                />

                            </v-col>
                        </template>
                    </v-row>
                </v-container>

                <!-- Pagination -->
                <v-container class="pa-0" fluid>
                    <v-row>
                        <v-col>
                            <c-item-list-pagination :items="modelRows" :pagination="pagination" @load-more="onLoadMore"/>
                        </v-col>
                    </v-row>
                </v-container>
            </template>

             <!-- Filter -->
             <v-navigation-drawer
                   fixed
                   right
                   temporary
                   v-model="filterDialog.display"
                   width="500"
                >
                    <v-card tile flat>
                        <v-toolbar flat>
                            <v-toolbar-title>{{ $t('battery-material-matrix.view.dialogs.filterSettings.title') }}</v-toolbar-title>
                            <v-spacer />
                            <v-toolbar-items>
                                <v-btn text @click="applyFilterSettings" color="accent">{{$t('battery-material-matrix.view.dialogs.filterSettings.actions.apply')}}</v-btn>
                                <v-btn text @click="resetFilterSettings" color="accent">{{$t('battery-material-matrix.view.dialogs.filterSettings.actions.reset')}}</v-btn>
                                <v-btn icon @click="hideFilterDialog"><v-icon>close</v-icon></v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-divider />
                        <v-expansion-panels accordion>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <div>{{$t('battery-material-matrix.view.dialogs.filterSettings.sections.batteryModels')}}</div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-card-text>
                                        <c-item-checkbox-multi-selector 
                                            v-model="filterDialog.selection.batteryModels"
                                            :items="batteryModels" 
                                        />
                                    </v-card-text>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <div>{{$t('battery-material-matrix.view.dialogs.filterSettings.sections.manufacturers')}}</div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-card-text>
                                        <c-item-checkbox-multi-selector 
                                            v-model="filterDialog.selection.manufacturers"
                                            :items="manufacturers" 
                                        />
                                    </v-card-text>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card>
                </v-navigation-drawer>

                <!-- View -->
                <v-navigation-drawer
                   fixed
                   right
                   temporary
                   v-model="viewDialog.display"
                   width="500"
                >
                    <v-card tile flat>
                        <v-toolbar flat>
                            <v-toolbar-title>{{ $t('battery-material-matrix.view.dialogs.viewSettings.title') }}</v-toolbar-title>
                            <v-spacer />
                            <v-toolbar-items>
                                <v-btn text @click="applyViewSettings" color="accent">{{$t('battery-material-matrix.view.dialogs.viewSettings.actions.apply')}}</v-btn>
                                <v-btn text @click="resetViewSettings" color="accent">{{$t('battery-material-matrix.view.dialogs.viewSettings.actions.reset')}}</v-btn>
                                <v-btn icon @click="hideViewDialog"><v-icon>close</v-icon></v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-divider />
                        <v-subheader>{{$t('battery-material-matrix.view.dialogs.viewSettings.sections.batteryMaterial')}}</v-subheader>
                        <v-card-text>
                            <c-item-checkbox-multi-selector 
                                v-model="viewDialog.selection.batteryMaterials"
                                :items="batteryMaterials" 
                            />
                        </v-card-text>
                    </v-card>
                </v-navigation-drawer>
        </v-card>
        
    </dxs-app-form>
</template>

<script>
import AppFormComponent from '@/core/components/apps/app-form.component';

import DataDistributionCardComponent from '@/core/components/data-exploration/distribution/data-distribution-card.component';
import BatteryMaterialMatrixModule from '@/solutions/battery-data-hub/store/apps/battery-material-matrix/battery-material-matrix.module';
import ItemListPaginationComponent from '@/core/components/pagination/item-list-pagination.component.vue';
const MODULE_NAME = 'battery-data-hub:apps:battery-material-matrix:battery-material-matrix'; 

const VIEW_TYPE_TABLE = 'VIEW_TYPE_TABLE';
const VIEW_TYPE_TILE = 'VIEW_TYPE_TILE';

const PAGE_SIZE = 100;

export default {

    name: 'battery-material-matrix',

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    components: {
        'dxs-app-form' : AppFormComponent,
        'dxs-data-distribution-card' : DataDistributionCardComponent,
        'c-item-list-pagination' : ItemListPaginationComponent
    },

    data() {

        return {

            viewType: VIEW_TYPE_TABLE,

            filterDialog: {
                display: false,
                selection: {
                    batteryModels: [],
                    manufacturers: []
                }
            },

            viewDialog: {
                display: false,
                selection: {
                    batteryMaterials: []
                },

  
                displayCategoryColumn: true,
                displayManufacturerColumn: true,
                displayBatteryPackCountColumn: true,

                displayEmptyRows: true
                
            },

            settingsSummaryDialog: {
                display: false
            }
        };
    }, 

    methods: {

        /**
         * Initializes this view, e.g. loads data from the backend.
         */
        async init() {
            this.progressIndicatorService.show();

            try {
                // First make sure that the view is reset properly. 
                await this.$store.dispatch(`${MODULE_NAME}/resetMatrix`);
                await this.$store.dispatch(`${MODULE_NAME}/resetContext`);
                await this.$store.dispatch(`${MODULE_NAME}/resetFilter`);
                await this.$store.dispatch(`${MODULE_NAME}/resetPagination`);

                // Init filter selection
                this.filterDialog.selection.batteryModels = [];
                this.filterDialog.selection.manufacturers = [];

                // Init view selection
                this.viewDialog.selection.batteryMaterials = [];

                // Now reload data
                this.progressIndicatorService.updateText(this.$t('battery-material-matrix.view.progress.batteryModels'));
                await this.$store.dispatch(`${MODULE_NAME}/loadContextBatteryModels`);

                this.progressIndicatorService.updateText(this.$t('battery-material-matrix.view.progress.manufacturers'));
                await this.$store.dispatch(`${MODULE_NAME}/loadContextManufacturers`);

                this.progressIndicatorService.updateText(this.$t('battery-material-matrix.view.progress.batteryMaterial'));
                await this.$store.dispatch(`${MODULE_NAME}/loadContextBatteryMaterials`);

                this.progressIndicatorService.updateText(this.$t('battery-material-matrix.view.progress.matrixData'));
                await this.$store.dispatch(`${MODULE_NAME}/loadMatrix`, {from: 0, pageSize: PAGE_SIZE});

            } catch (error) {
                this.errorHandlerService.handleError(error);

            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async refresh() {

            this.$log.debug(`Refresh: Resetting model and reloading data with current filter settings.`)
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(`${MODULE_NAME}/resetMatrix`);
                await this.$store.dispatch(`${MODULE_NAME}/loadMatrix`, {pageSize: PAGE_SIZE});

            } catch (error) {
                this.errorHandlerService.handleError(error);

            } finally {
                this.progressIndicatorService.hide();
            }            
        },



        async onLoadMore({from, pageSize}) {

            this.$log.debug(`Load Next Page: Load rows from ${from} to ${from  + pageSize} [page size ${pageSize}].`)
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(`${MODULE_NAME}/loadMatrixNextPage`, {from, pageSize});

            } catch (error) {
                this.errorHandlerService.handleError(error);

            } finally {
                this.progressIndicatorService.hide();
            }   
        },

        showFilterDialog() {
            this.filterDialog.display = true;
        },

        hideFilterDialog() {
            this.filterDialog.display = false;
        },
        
        showViewDialog() {
            this.viewDialog.display = true;
        },
        
        hideViewDialog() {
            this.viewDialog.display = false;
        },

        showSettingsSummaryDialog() {
            this.settingsSummaryDialog.display = true;
        }, 

        hideSettingsSummaryDialog() {
            this.settingsSummaryDialog.display = false;
        },

        toggleSettingsSummaryDialog() {
            this.settingsSummaryDialog.display = !this.settingsSummaryDialog.display ;
        },

        applyFilterSettings() {
            this.$store.dispatch(`${MODULE_NAME}/updateBatteryModelFilter`, { items: this.filterDialog.selection.batteryModels });
            this.$store.dispatch(`${MODULE_NAME}/updateManufacturerFilter`, { items: this.filterDialog.selection.manufacturers });
            this.hideFilterDialog();

            // Applying  filter settings will automatically trigger a refresh.
            this.refresh();
        },


        resetFilterSettings() {
            this.filterDialog.selection.batteryModels = [];
            this.filterDialog.selection.manufacturers = [];
            this.applyFilterSettings();
        },

        applyViewSettings() {
            this.$store.dispatch(`${MODULE_NAME}/updateBatteryMaterialFilter`, { items: this.viewDialog.selection.batteryMaterials });
            this.hideViewDialog();

            // Applying new view settings will automatically trigger a refresh.
            this.refresh();
        },

        resetViewSettings() {
            this.viewDialog.selection.batteryMaterials = [];
            this.applyViewSettings();
        },

        onScrollLeft() {

            // Synchronize the scrolling positions
            const tableLeft = this.$refs.tableLeft;

            const tableRight = this.$refs.tableRight;
            tableRight.scrollTop = tableLeft.scrollTop;

            const headerLeft = this.$refs.headerLeft;
            headerLeft.scrollLeft = tableLeft.scrollLeft;

        },

        onScrollRight() {
            
            // Synchronize the scrolling positions
            const tableLeft = this.$refs.tableLeft;
            const tableRight = this.$refs.tableRight;

            tableLeft.scrollTop = tableRight.scrollTop;

        },

        setViewTypeTable() {
            this.viewType = VIEW_TYPE_TABLE;
        },

        setViewTypeTile() {
            this.viewType = VIEW_TYPE_TILE;
        },

        canDisplayRow(row) {
            
            // If empty rows should be displayed we can return true anyway.
            if (this.viewDialog.displayEmptyRows) return true;
            else if (row.batteryPackCount > 0) return true;
            else return false; 
            
        }
        
    },

    computed: {

        isViewTypeTable() {
            return this.viewType === VIEW_TYPE_TABLE;
        }, 

        isViewTypeTile() {
            return this.viewType === VIEW_TYPE_TILE;
        }, 

        modelColumns() {
            return this.$store.getters[`${MODULE_NAME}/matrixColumns`];
        },    
        
        otherColumnsSummary() {
            return 0;
        },

        modelRows() {
            return this.$store.getters[`${MODULE_NAME}/matrixRows`];
        },

        modelRowsWithOther() {
           
            const rows = [];
            for (const row of this.modelRows) {
                let count = 0;
                for (const key in row.material) {
                    count = count + row.material[key];
                }
                row['other'] = row.combinedShare - count;
                rows.push(row);
            }
            return rows;
        },

        modelRowsEmpty() {
            return this.modelRows.length === 0;
        },

        batteryModels() {
            return this.$store.getters[`${MODULE_NAME}/batteryModels`];
        },

        manufacturers() {
            return this.$store.getters[`${MODULE_NAME}/manufacturers`];
        },

        batteryMaterials() {
            return this.$store.getters[`${MODULE_NAME}/batteryMaterials`];
        },

        batteryModelFilter() {
            return this.$store.getters[`${MODULE_NAME}/batteryModelFilter`];
        },

        batteryMaterialFilter() {
            return this.$store.getters[`${MODULE_NAME}/batteryMaterialFilter`];
        },

        totalItemCount() {
            return this.$store.getters[`${MODULE_NAME}/totalItemCount`];
        },

        pagination() {
            return this.$store.getters[`${MODULE_NAME}/pagination`];
        },

        chartValues() {

            const values = [];
            for (const row of this.modelRowsWithOther) {

                const items = []; 
                for (const material of this.modelColumns) {
                    const item = {
                        name: material.name,
                        value: row.material[material.name] ? row.material[material.name]  : 0, 
                        color: material.data.color
                    };
                    
                    items.push(item);
                }

                // Add the summary item for other 
                items.push({
                    name: '...',
                    value: row.other,
                    color: 'grey'
                })

                values.push({
                    name: row.batteryModel.name,
                    i18n: row.batteryModel.i18n,
                    items: items,
                    batteryPackCount: row.batteryPackCount
                });
            }

            return values;
        }
        
    },

    async created() {
        this.$log.debug(`Creating Battery Material Matrix.`)
        if (!this.$store.hasModule(MODULE_NAME)) {
            this.$store.registerModule(MODULE_NAME, BatteryMaterialMatrixModule);
            this.$log.debug(`Registered new Vuex Store ${MODULE_NAME}`);

        } else {
            this.$log.debug(`Vuex Store ${MODULE_NAME} is already registered.`)
        }        

        // Initialize the view.
        await this.init();
    },

    destroyed() {
        if (this.$store.hasModule(MODULE_NAME)) {
            this.$store.unregisterModule(MODULE_NAME);
            this.$log.debug(`Vuex Store ${MODULE_NAME} has been unregistered.`)
        }        
    }
}
</script>

<i18n>
{
    "en" : {
        "battery-material-matrix.view.title" : "Battery Material Matrix",
        "battery-material-matrix.view.subtitle" : "This dialog shows an overview of the current battery materials per model.",
        
        "battery-material-matrix.view.dialogs.filterSettings.title" : "Filter",
        "battery-material-matrix.view.dialogs.filterSettings.actions.apply" : "Apply",
        "battery-material-matrix.view.dialogs.filterSettings.actions.reset" : "Reset",
        "battery-material-matrix.view.dialogs.filterSettings.sections.batteryModels" : "Battery Models (Rows)",
        "battery-material-matrix.view.dialogs.filterSettings.sections.manufacturers" : "Manufacturers (Rows)",

        "battery-material-matrix.view.dialogs.viewSettings.title" : "View",
        "battery-material-matrix.view.dialogs.viewSettings.actions.apply" : "Apply",
        "battery-material-matrix.view.dialogs.viewSettings.actions.reset" : "Reset",
        "battery-material-matrix.view.dialogs.viewSettings.sections.batteryMaterial" : "Battery Material (Columns)",

        "battery-material-matrix.view.dialogs.settings.summary.selection" : "{count} items selected.",

        "battery-material-matrix.view.columns.batteryModel" : "Battery Model",
        "battery-material-matrix.view.columns.category" : "Category",
        "battery-material-matrix.view.columns.manufacturer" : "Manufacturer",
        "battery-material-matrix.view.columns.batteriesTotal" : "Battery Count",
        "battery-material-matrix.view.result.totalItemCount" : "Result ({totalItemCount} Rows)",

        "battery-material-matrix.view.actions.view.table" : "Display as Table",
        "battery-material-matrix.view.actions.view.tiles" : "Display as Tiles",

        "battery-material-matrix.view.actions.view.showEmptyRows" : "Show Empty Rows",
        "battery-material-matrix.view.dialogs.rows.emptyRowsHidden" : "Empty rows are currently hidden from display.",

        "battery-material-matrix.view.tiles.subtitle" : "{count} Battery Packs",

        "battery-material-matrix.view.noData.title" : "No Data Found",
        "battery-material-matrix.view.noData.subtitle" : "The system did not find any data which matches your filter criteria. Please adjust your filters in order to proceed.",

        "battery-material-matrix.view.progress.batteryModels" : "Loading Battery Models..",
        "battery-material-matrix.view.progress.batteryMaterial" : "Loading Battery Materials...",
        "battery-material-matrix.view.progress.matrixData" : "Batterie-Material-Matrix wird berechnet..."
    },

    "de" : {
        "battery-material-matrix.view.title" : "Batterie-Material-Matrix",
        "battery-material-matrix.view.subtitle" : "Dieser Dialog zeigt eine gruppierte Übersicht der Batterie-Materialien pro Batterie-Modell.",
        
        "battery-material-matrix.view.dialogs.filterSettings.title" : "Filter",
        "battery-material-matrix.view.dialogs.filterSettings.actions.apply" : "Anwenden",
        "battery-material-matrix.view.dialogs.filterSettings.actions.reset" : "Zurücksetzen",
        "battery-material-matrix.view.dialogs.filterSettings.sections.batteryModels" : "Batterie-Modelle (Zeilen)",
        "battery-material-matrix.view.dialogs.filterSettings.sections.manufacturers" : "Hersteller (Zeilen)",

        "battery-material-matrix.view.dialogs.viewSettings.title" : "Ansicht",
        "battery-material-matrix.view.dialogs.viewSettings.actions.apply" : "Anwenden",
        "battery-material-matrix.view.dialogs.viewSettings.actions.reset" : "Zurücksetzen",
        "battery-material-matrix.view.dialogs.viewSettings.sections.batteryMaterial" : "Batterie-Material (Spalten)",

        "battery-material-matrix.view.dialogs.settings.summary.selection" : "{count} Elemente ausgewählt.",
        "battery-material-matrix.view.dialogs.rows.emptyRowsHidden" : "Alle leeren Zeilen werden derzeit ausgeblendet.",


        "battery-material-matrix.view.columns.batteryModel" : "Batterie-Modell",
        "battery-material-matrix.view.columns.category" : "Kategorie",
        "battery-material-matrix.view.columns.manufacturer" : "Hersteller",
        "battery-material-matrix.view.columns.batteriesTotal" : "Anzahl Batterien",
        "battery-material-matrix.view.result.totalItemCount" : "Ergebnis ({totalItemCount} Zeilen)",

        "battery-material-matrix.view.actions.view.table" : "Als Tabelle anzeigen",
        "battery-material-matrix.view.actions.view.tiles" : "Als Kacheln anzeigen",

        "battery-material-matrix.view.actions.view.showEmptyRows" : "Leere Zeilen anzeigen",

        "battery-material-matrix.view.tiles.subtitle" : "{count} Batterien",

        "battery-material-matrix.view.noData.title" : "Keine Daten zur Anzeige",
        "battery-material-matrix.view.noData.subtitle" : "Das System konnte keine passenden Daten für Ihre eingestellten Filter finden. Bitte passen Sie die Filter ggf. an um fortzufahren.",

        "battery-material-matrix.view.progress.batteryModels" : "Batterie-Modelle werden geladen...",
        "battery-material-matrix.view.progress.batteryMaterial" : "Batterie-Materialien werden geladen...",
        "battery-material-matrix.view.progress.matrixData" : "Batterie-Material-Matrix wird berechnet..."
    }
}
</i18n>

<!-- Make sure that the style will always be scoped. This will cause a lot of trouble if scoping does not take place. -->
<style scoped>

.matrix {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse; 
}


.table-container-left {
  max-height: 70vh; 
  overflow-y: hidden;
  overflow-x: auto;
}

.header-container-left {
  max-height: 70vh; 
  overflow-y: hidden;
  overflow-x: hidden;
}

.table-container-right {
  max-height: 70vh; 
  overflow-y: auto;
  overflow-x: auto;
}

.matrix-cell-left {
    padding: 10px;
    width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.matrix-cell-right {
    padding: 10px;
    width: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cas-link {
   text-decoration: none;
}

.cas-link:hover {
   text-decoration: underline;
}
</style>