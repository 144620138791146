import { get, set } from 'lodash';
import api from '../../../api/battery-data-hub.api';

export default {
    namespaced: true,

    state: {
        Assignments: [],
        tenantId: null,
        selected:[],
        novel: [],
        possibleAssignments:[],
        modelName: null
    },
    mutations:{
        setState(state){
            state.Assignments = [];
            state.selected = [];
            state.possibleAssignments = [];
            state.novel = [];
        },
        setAssignments(state, Assignments){
            state.Assignments = Assignments;
        },
        updatePossibleAssignments(state){
            state.possibleAssignments = state.Assignments.filter(assignment => !state.selected.some(selected => selected.name === assignment.name));
        },
        setSelecetd(state, selected){
            console.log(selected);
            state.selected = selected;
        },
        setPossibleAssignments(state, Assignments){
            console.log(Assignments);
            state.possibleAssignments = Assignments.filter(assignment => !state.selected.some(selected => selected === assignment.name));
        },
        setNovel(state, novel){
            state.novel = novel;
        },
        setName(state, name){
            state.modelName = name;
        },
        saveAssignments(state){
            let novel = [];
            console.log("*****************novel**************************");
            const modelId = state.modelName;
           
            state.novel.forEach(element => { 
                const payload = {
                tenantId: "",
                name: "",
                data:{custData:{}},
                i18n:{},	
                active: true,
                references: {
                    safetyinstructionid: element,
                    batterymodelid: modelId
                }
            };
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
                novel.push(payload);
            });
            console.log(novel);
            const n = api.masterdata.safetyAssignment.updateBulk(novel,modelId);
            console.log("*****************novel**************************");
            console.log(n);
        }
        
        
    },
    actions:{
        async loadData(context,name){
            try{
            const response = await api.masterdata.safetyInstructions.findByFilter(context.state.filter, 0, 50);
            context.commit('setAssignments', get(response,"items",[]));
            let response_selected = await api.masterdata.batteryModels.findSafetyAssignmentByBatteryModelId(name, 0, 50);
            console.log(response_selected);
            console.log("*****************name Selected**************************");
            console.log(name);
            response_selected.items = response_selected.items.map(item => {return item.references.safetyinstructionid});
            console.log(response_selected);
            console.log("*****************name Selected**************************");
            context.commit('setSelecetd', get(response_selected,"items",[]));
            context.commit('setPossibleAssignments', get(response,"items",[]));
            context.commit('setName', name);
            } catch(error){
            context.commit('setAssignments', []);
            context.commit('setSelecetd', []);
            context.commit('setSelecetd', []);
            context.commit('setName', null);
            return Promise.reject(error);
            }
        },
        async saveData(context, {name}){
            try{
                const novel = context.state.novel
                await api.masterdata.safetyAssignment.updateBulk(novel, name);
            } catch(error){
                console.log(error);
                return Promise.reject(error);
            }
        },
        async createAssignment(context){
            try{
            await context.commit('saveAssignments');
            }
            catch(error){
            console.log("An error has occured" + error);
            return Promise.reject(error);
            }
        },
        async reset(context) {
            try{
            context.commit('setState');
            }catch(error){
            return Promise.reject(error);
            }
        },

        async updateTenantId(context, tenantId) {
            try {
                context.commit('setTenantId', tenantId);
            } catch (error) {
                return Promise.reject(error);
            }
        },
        async setAssignment(context, assignment){
            context.commit('setNovel', assignment);
        }

    },
    getters:{
        safetyInstructions(state){
            return state.possibleAssignments;
        },
        selected(state){
            return state.selected.map(v => v.references.batterymodelid);
        },
        possibleAssignments(state){
            return state.possibleAssignments;
        },
        assignment(state){
            return state.selected;
        }
    }
}