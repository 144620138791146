<template>
    <v-container fluid class="secondary pt-10 pb-10">
        <v-row justify="center">
            <v-col cols="12" lg="9">
                <c-base-dialog :value="assignment" :title="$t('battery-model-wizard.view.title')"
                    :subtitle="$t('battery-model-wizard.view.subtitle')" color="secondary" @dialog-saved="save"
                    @dialog-canceled="cancel">
                    <template>
                        <v-card flat>
                            <v-card-text>
                                <c-item-checkbox-multi-selector v-model="assignment" :items="safetyInstructions"
                                    :rules="[v => v && !!v.trim() || $t('battery-model-wizard.view.category.valuecheck')]"
                                    :label="$t('battery-model-wizard.view.category')" filled></c-item-checkbox-multi-selector>
                            </v-card-text>
                        </v-card>
                    </template>
                </c-base-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AssignmentWizardModule from "../../../store/masterdata/battery-models/assignemnt-wizard.module.js";
import Roles from "@/roles";
const MODULE_NAME = "AssignmentWizard";

export default {
  name: "assignment-wizard",

  inject: [
    "errorHandlerService",
    "progressIndicatorService",
    "envContextService",
    "userContextService",
  ],

  props: {

value: {
    type: Object,
    required: true,
}
},
  methods: {
    async cancel(){
      window.close();
    },
    save() {
      this.progressIndicatorService.show();
      try {
      this.$store.dispatch(MODULE_NAME + "/createAssignment");
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
      window.close();
      window.opener.location.reload();
    },
    hasEditRole() {
      return this.userContextService.hasRole(Roles.BATTERYMODEL_EDIT);
    },
    hasReadRole() {
      return this.userContextService.hasRole(Roles.SECURITYINSTRUCTIONS_READ);
    },
    async reset(name){
      console.log("*******************RESET******************")
      console.log(name);
      console.log("*************************************")
        await this.$store.dispatch(MODULE_NAME + "/reset");
        await this.$store.dispatch(MODULE_NAME + "/loadData",name);
    }
  },
computed: {
    safetyInstructions(){
            return this.$store.getters[MODULE_NAME + '/possibleAssignments'];
        },
    assignment:{
        get(){
            return this.$store.getters[MODULE_NAME + '/assignment'];
        },
        set(value){
            this.$store.dispatch(MODULE_NAME + '/setAssignment', value);
        }
    },
},
async beforemount(){
    if (!this.$store.hasModule(MODULE_NAME)) {
      this.$store.registerModule(MODULE_NAME, AssignmentWizardModule);//todo
  }
    this.reset(name);
  },

async created() {
    if (!this.$store.hasModule(MODULE_NAME)) {
      await this.$store.registerModule(MODULE_NAME, AssignmentWizardModule);//todo
  }
  const name = this.value.name;
  this.reset(name);
    console.log("*******************CREATED******************")
    console.log(name);
    console.log("*************************************")
  await this.$store.dispatch(MODULE_NAME + "/loadData",name);//todo
},

destroyed() {
    // Unregister the vuex store if it has been registered before.
    if (this.$store.hasModule(MODULE_NAME)) {
        this.$store.unregisterModule(MODULE_NAME);
        this.$log.debug(`Vuex Store ${MODULE_NAME} has been unregistered.`);
    }
}
};
</script>
  
<i18n>
  {
      "en" : {
          "battery-model-wizard.view.title" : "Assign Safety Instructions",
          "battery-model-wizard.view.subtitle" : "This wizard will guide you through the required steps for assigning a Safety Instruction.",
          "battery-model-wizard.view.category":"Safety Instructions",
          "battery-model-wizard.view.category.valuecheck":"Please assign a Safetey Instruction."
      },
   
      "de" : {
        "battery-model-wizard.view.title" : "Sicherheitsanweisungen zuweisen",
        "battery-model-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die erforderlichen Schritte zum Zuweisen einer Sicherheitsanweisung.",
        "battery-model-wizard.view.category":"Sicherheitsanweisungen",
        "battery-model-wizard.view.category.valuecheck":"Bitte weisen Sie eine Sicherheitsanweisung zu."
      }
  }
  </i18n>